.AspectRatioBox {
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}

.AspectRatioBox__Inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
